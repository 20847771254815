import React, {useContext} from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import PageBanner from '../components/pageBanner'
import {GlobalStateContext} from '../context/GlobalContextProvider'

export default function Faq({data, location}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const {faq, menu} = state.language
    return(
        <LayoutMaster location={location} title={siteTitle}>
          <SEO title={menu[6].text} />
            <PageBanner>
              <h2>{faq.title}</h2>
              <section style={{marginTop:30}}>
                {
                  faq.text.map((item, index)=>(
                    <details key={index}>
                      <summary><h4>{item.question}</h4></summary>
                      {index===0 ? (<p><Link to="/">{item.answer}</Link></p>) : (<p>{item.answer}</p>) }
                    </details>
                  ))
                }
              </section>
            </PageBanner>
        </LayoutMaster>
    )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`